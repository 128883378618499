import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
export const _frontmatter = {
  "title": "Arbour Assembly - Attaching the Main Beams to the Posts"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Step 3. Attaching the Main Beams to the Posts`}</h1>
    <p>{`The next job to tackle is attaching the main beams to the posts. Line up the pre-drilled holes on the posts and the main beams and thread the coach bolts through, fix with the nut and tighten. Do not forget to put the washer on first as this will help prevent the bolt head from damaging the wood and make it easier to undo in future if required. For best results, push the coach bolt through leaving the head visible on the outside of the post and the bulky nut on the inside (See Fig 15 & 16 below)`}</p>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-3-attaching-the-main-beams-to-the-posts/n-fig15.jpg" alt="A hand-drawn diagram demonstrating how to fit the main beam to the to the post using a coach bolt and a washer. The post protrudes 2400mm from the ground." />
  <span className="text-metadata">Fig 15</span>
    </div>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-3-attaching-the-main-beams-to-the-posts/arbour-fig-16.jpg" alt="A hand-drawn diagram demonstrating the main beam and post assembly from the side, measuring 2700mm long." />
  <span className="text-metadata">Fig 16</span>
    </div>
    <p>{`If you have ordered either the 9ft x 16ft or 9ft x 23ft pergola modules, you will need to join together two main beams (for the 9ft x 16ft module) or two main beams plus the central extension board (for nthe 9ft x 23ft module) using the supplied 3-Way ‘T’ shaped fixing plate as illustrated below :`}</p>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-3-attaching-the-main-beams-to-the-posts/n-fig17.jpg" alt="A hand-drawn diagram demonstrating how to fit the main beam to the to the post for 9ft x 16ft and 9ft x 23ft pergola modules, using a 3 way fixing bracket." />
  <span className="text-metadata">Fig 17</span>
    </div>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-3-attaching-the-main-beams-to-the-posts/n-fig18.jpg" alt="A hand-drawn diagram demonstrating a birds-eye view of the main beam to post attachment displayed in fig 17." />
  <span className="text-metadata">Fig 18</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      